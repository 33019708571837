import base64js from "base64-js";
import base, { APIDetail, APIList, APISearchParams, config } from "./base";

export type APIInputSheetFileType =
  | "rm_working"
  | "capex_summary"
  | "oh_full_pack_mfg"
  | "oh_full_logs"
  | "oh_marg_pack_mfg"
  | "oh_marg_logs"
  | "rm_price_assumptions"
  | "marginal_cost_percent_country"
  | "marginal_cost_percent_plants";

export interface APIInputSheet {
  id: identifier;
  project: identifier;
  cluster: identifier;
  clusterName: string;
  fileType: APIInputSheetFileType;
  file: string;
  fileEu: string;
  errorFile: string;
  isCltLocked: boolean;
  isCltApproved: boolean;
  isCltSubmitted: boolean;
  isEditable: boolean;
}

export type APIInputSheetInput = Partial<
  Omit<
    APIInputSheet,
    | "id"
    | "project"
    | "cluster"
    | "clusterName"
    | "isCltLocked"
    | "isCltApproved"
    | "isCltSubmitted"
    | "isEditable"
    | "fileEu"
  >
>;

export interface APIInputSheetList extends APIList<APIInputSheet> {}

export interface APIInputSheetSearchParams extends APISearchParams {
  project?: number;
  cluster?: number;
  file_type?: APIInputSheetFileType;
  file_type__in?: APIInputSheetFileType[];
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIInputSheetSearchParams
) =>
  base.list<APIInputSheetList, APIInputSheetSearchParams>(
    "inputs-sheets/",
    page,
    limit,
    searchParams
  );

export interface APIInputSheetDetail extends APIDetail<APIInputSheet> {}

export const get = (sheetId: identifier) =>
  base.get<APIInputSheetDetail>(`inputs-sheets/${sheetId}/`);

export const update = (sheetId: identifier, csvData?: string) =>
  base.patch<APIInputSheetDetail>(`inputs-sheets/${sheetId}/`, {
    json: csvData
      ? {
          file: base64js.fromByteArray(Buffer.from(csvData)),
        }
      : {},
  });

export const autofill = (sheetId: identifier) =>
  base.post<APIInputSheetDetail>(`inputs-sheets/${sheetId}/autofill/`);
